<template>
    <div class="row">
        <div class="col-md-6 form-group">
            <b-form-select id="province" v-model="provinceValue" :options="provinceOptions" @change="changeProvince();">
            </b-form-select>
        </div>
        <div class="col-md-6 form-group">
            <b-form-select id="district" v-model="districtValue" :options="districtOptions" @change="changeDistrict();">
            </b-form-select>
        </div>
        <!-- <div class="col-md-12 form-group">
            <b-form-select id="ward" v-model="wardValue" :options="wardOptions" @change="responseAddress();">
            </b-form-select>
        </div> -->
        <!-- <div class="col-md-12">
            <input type="text" class="form-control" id="street" v-model="streetValue" @change="responseAddress();"
                placeholder="Nhập số nhà, ngõ, tên đường" autocomplete="off" />
        </div> -->
    </div>
</template>


<script>
// import ApiAddress from "../../services/apiAddress";
import { getProvinces } from "../../common/storage";

export default {
    props: {
        province: String,
        district: String,
        ward: String,
        street: String,
        callBack: {
            type: Function
        }
    },
    data() {
        return {
            provinceValue: "",
            districtValue: "",
            wardValue: "",
            streetValue: "",
            provinceOptions: [],
            provinceItem: { value: "", text: 'Tỉnh/Thành phố' },
            districtOptions: [],
            districtItem: { value: "", text: 'Quận/Huyện' },
            wardOptions: [],
            wardItem: { value: "", text: 'Phường/Xã' },
            loadingItem: { value: "", text: 'Đang tải...' }
        };
    },
    methods: {
        getAddress(p, d, w, s) {
            var result = "";
            if (s) result += s;
            if (w) result += (result ? ", " : "") + this.getTextOption(w, this.wardOptions);
            if (d) result += (result ? ", " : "") + this.getTextOption(d, this.districtOptions);
            if (p) result += (result ? ", " : "") + this.getTextOption(p, this.provinceOptions);
            return result;
        },
        responseAddress() {
            const address = this.getAddress(this.provinceValue, this.districtValue, this.wardValue, this.streetValue);
            this.callBack(this.provinceValue, this.districtValue, this.wardValue, this.streetValue, address);
        },
        changeProvince(district = null, ward = null) {
            this.districtOptions = [this.districtItem];
            this.districtValue = "";
            this.wardOptions = [this.wardItem];
            this.wardValue = "";
            if (this.provinceValue) this.getDistrict(this.provinceValue, district, ward);
            this.responseAddress();
        },
        changeDistrict(ward = null) {
            this.wardOptions = [this.wardItem];
            this.wardValue = "";
            // if (this.districtValue) this.getWard(this.districtValue, ward);
            this.responseAddress();
            console.log(ward);
        },
        async getProvince(province = null, district = null, ward = null) {
            this.provinceOptions = [this.loadingItem];
            // this.showLoading(true);
            // const provinces = await ApiAddress.getProvinces();
            const provinces = getProvinces();
            // this.showLoading(false);
            if (provinces && provinces.length > 0) this.provinceOptions = provinces.map(item => {
                return { value: item.province_id, text: `${item.type} ${item.name}` };
            });
            this.provinceOptions.unshift(this.provinceItem);
            if (province) {
                this.provinceValue = province;
                this.changeProvince(district, ward);
            }
        },
        async getDistrict(provinceId, district = null, ward = null) {
            this.districtOptions = [this.loadingItem];
            // this.showLoading(true);
            // const districts = await ApiAddress.getDistricts(provinceId);
            const districts = this.getDistrictBys(provinceId);
            // this.showLoading(false);
            if (districts && districts.length > 0) this.districtOptions = districts.map(item => {
                return { value: item.district_id, text: `${item.type} ${item.name}` };
            });
            this.districtOptions.unshift(this.districtItem);
            if (district) {
                this.districtValue = district;
                this.changeDistrict(ward);
            }
        },
        // async getWard(districtId, ward = null) {
        //     this.wardOptions = [this.loadingItem];
        //     this.showLoading(true);
        //     const wards = await ApiAddress.getWards(districtId);
        //     this.showLoading(false);
        //     if (wards && wards.length > 0) this.wardOptions = wards.map(item => {
        //         return { value: item.ward_id, text: `${item.type} ${item.name}` };
        //     });
        //     this.wardOptions.unshift(this.wardItem);
        //     if (ward) {
        //         this.wardValue = ward;
        //         this.responseAddress();
        //     }
        // }
    },
    mounted() {
        this.streetValue = this.street;
        this.provinceOptions.unshift(this.provinceItem);
        this.districtOptions.unshift(this.districtItem);
        this.wardOptions.unshift(this.wardItem);
        this.getProvince(this.province, this.district, this.ward);
    }
};
</script>
